<template>
  <div id="content">
    <form v-on:submit.prevent="onSubmit" @submit="submitForm">
      <h2>Log in</h2>
      <p class="errors" v-if="errors.length">
        <ul>
          <li v-for="error in errors" :key="error.key">{{ error }}</li>
        </ul>
      </p>

      <div class="control">
        <input v-model="email" type="email" placeholder="E-mail" name="login_form[email]" id="login_form_email">
      </div>

      <div class="control">
        <input v-model="password" placeholder="Password" type="password" name="login_form[password]" id="login_form_password">
      </div>

      <div class="control">
        <label class="checkbox">
          <input v-model="remember" type="checkbox" name="remember" id="remember" value="true" class="checkbox">  Stay logged in
        </label>
      </div>

      <div class="center">
        <button type="submit" class="button reverse">
          <inline-svg
            :src="require('../../assets/icons/login.svg')"
          ></inline-svg>
          Log in
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useAuthStore } from '../../store/auth';
import TripService from "@/services/trip.service";
import { useTripStore } from '../../store/trip';

export default {
  name: 'Auth#Login',
  data() {
    return {
      errors: [],
      email: '',
      password: '',
      remember: false,
      active: false
    }
  },
  mounted() {
  },
  methods: {
    submitForm: function (e) {
      var user = { email: this.email, password: this.password };
      const auth = useAuthStore();
      auth.login(user);

      var tripStore = useTripStore();
      tripStore.getTrips();

      this.$router.push("/trips");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
#content
  max-width: 500px
  margin: 0 auto
  margin-bottom: 0px
  text-align: left
  color: #333
  border-radius: 30px
  padding: 40px 40px
  background-color: white
  margin-bottom: 2rem
  box-shadow: 5px 11px 15px -3px rgba(0,0,0,0.1), 4px 3px 2px -4px rgba(0,0,0,0.1)

  h2
    text-align: center
    margin-bottom: 4rem

  form
    margin: 0

  .control
    margin-bottom: 2rem

  .errors
    font-size: 1.7rem
    color: red

    li
      margin-bottom: 0
      font-weight: 500
</style>
